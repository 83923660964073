var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Header'),_c('pageTitle',{attrs:{"txt":"追加発注 詳細","backBtn":_vm.backBtn,"backURL":"/iseya/order/extraOrder/"}}),_c('div',{attrs:{"id":"meta"}},[_c('span',{staticClass:"shopName"},[_vm._v(_vm._s(_vm.$route.query.shopName))]),_c('span',{staticClass:"shopTel"},[_vm._v("TEL : "+_vm._s(_vm.$route.query.tel))]),_c('div',{staticClass:"meta-btn"},[_c('router-link',{attrs:{"to":{
          path: '/iseya/order/extraDetail/edit/',
          query: {
            shopID: _vm.$route.query.shopID,
            shopName: _vm.$route.query.shopName,
            tel: _vm.$route.query.tel,
            date: _vm.$route.query.date
          }
        }}},[_c('btnS',{staticClass:"edit",attrs:{"btnTxt":"編集","color":"positive1"}})],1),_c('div',{on:{"click":function($event){_vm.print = !_vm.print;
          _vm.setPrint();}}},[_c('btnS',{staticClass:"print",attrs:{"btnTxt":"印刷","color":"primary"}})],1)],1)]),_c('div',{attrs:{"id":"wrapper"}},[(_vm.orderList.length > 0)?_c('ul',{staticClass:"list"},[_c('li',{staticClass:"list-head"},[_c('ul',{staticClass:"product"},[_c('li',{staticClass:"date"}),_c('li',{staticClass:"vacuum"}),_vm._l((_vm.orderList[0].products),function(product){return _c('li',{key:product.item_id,staticClass:"product-item"},[_vm._v(" "+_vm._s(product.name)+" ")])})],2)]),_vm._l((_vm.orderList),function(item,key){return _c('orderDetailItem',{key:item.id,staticClass:"weekday",class:{
          saturday: item.weekName == '土',
          holiday: item.weekName == '日' || item.is_holiday == '1'
        },attrs:{"item":item,"products":item.products,"year":item.year,"month":item.month,"date":item.day,"day":key,"orderNum":key,"dayName":item.weekName,"time":item.thisDayTime,"extra":true}})})],2):_vm._e()]),_c('div',{class:{ visible: _vm.print },attrs:{"id":"overlay"}},[_vm._l((this.printNum),function(paper){return _c('div',{key:paper,staticClass:"print-paper landscape"},[_c('div',{staticClass:"print-inner"},[_c('div',{attrs:{"id":"print-meta"}},[_c('p',{staticClass:"print-ttl"},[_vm._v(_vm._s(_vm.$route.query.shopName))]),_c('p',{staticClass:"print-period"},[_vm._v(" "+_vm._s(_vm._f("dateFormatShort")(_vm.start_date))+" ～ "+_vm._s(_vm._f("dateFormatShort")(_vm.end_date))+" ")]),_c('span',{staticClass:"print-shopName"},[_vm._v(_vm._s(_vm.$route.query.shopName))]),_c('span',{staticClass:"print-shopTel"},[_vm._v("TEL : "+_vm._s(_vm.$route.query.tel))]),_c('div',{staticClass:"print-time"},[_vm._v(" "+_vm._s(_vm._f("dateFormatShort")(_vm.today))+" 現在 "),_c('br'),_vm._v(" "+_vm._s(paper)+"/"+_vm._s(_vm.printNum)+"頁 ")])]),(_vm.orderList.length > 0)?_c('ul',{staticClass:"list"},[_c('li',{staticClass:"list-head"},[_c('ul',{staticClass:"product"},[_c('li',{staticClass:"date"}),_c('li',{staticClass:"vacuum"}),_vm._l((_vm.orderList[0].products.slice(
                  paper * _vm.dataPerPage - _vm.dataPerPage,
                  paper * _vm.dataPerPage
                )),function(product){return _c('li',{key:product.id,staticClass:"product-item"},[_vm._v(" "+_vm._s(product.name)+" ")])})],2)]),_vm._l((_vm.orderList),function(item,key){return _c('orderDetailItemPrint',{key:item.id,staticClass:"weekday",class:{
              saturday: item.weekName == '土',
              holiday: item.weekName == '日' || item.is_holiday == '1'
            },attrs:{"products":item.products,"dataPerPage":_vm.dataPerPage,"index":paper,"pagePerItems":_vm.pagePerItems,"year":item.year,"month":item.month,"date":item.day,"day":key,"orderNum":key,"dayName":item.weekName,"time":item.thisDayTime,"extra":true}})})],2):_vm._e()])])}),_c('div',{attrs:{"id":"fixedMenu"}},[_c('div',{on:{"click":function($event){_vm.print = !_vm.print;
          _vm.removeSetPrint();}}},[_c('btnS',{attrs:{"btnTxt":"戻る","color":"back"}})],1),_c('div',{attrs:{"onclick":"print()"}},[_c('btnL',{attrs:{"btnTxt":"この内容で印刷する","color":"primary"}})],1)])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }